import { platformData, PlatformData } from './platform.capacitor'
import { assertNotNull } from '@/helpers/typing'
import { MEDIUM_WIDTH, MOBILE_WIDTH, TABLET_WIDTH } from '@/init/settings'

export { PlatformData }

export interface Platform {
  _data: PlatformData | null

  init: () => Promise<PlatformData>
  deviceInfo: () => Promise<PlatformData>
  isNativeIOSApp: () => Promise<boolean>
  isNativeAndroidApp: () => Promise<boolean>
  isNativeApp: () => Promise<boolean>
  isMobileWidth: () => boolean
  isMediumWidth: () => boolean
  isTabletWidth: () => boolean
  isDesktopWidth: () => boolean
  isTouchScreen: () => boolean
  openUrl: (url: string) => Promise<void>
}

export const platform: Platform = {
  _data: null,

  async init(): Promise<PlatformData> {
    if (this._data === null) {
      await platformData.init()
      this._data = platformData
    }
    return new Promise((resolve) => {
      resolve(assertNotNull(this._data))
    })
  },

  async deviceInfo(): Promise<PlatformData> {
    const data = await this.init()
    return data
  },

  /**
   * Retruns true if current platform is native iOS app.
   */
  async isNativeIOSApp(): Promise<boolean> {
    const info = await this.deviceInfo()
    return info.platform === 'ios'
  },

  /**
   * Retruns true if current platform is native Android app.
   */
  async isNativeAndroidApp(): Promise<boolean> {
    const info = await this.deviceInfo()
    return info.platform === 'android'
  },

  /**
   * Retruns true current platform is native mobile app.
   */
  async isNativeApp(): Promise<boolean> {
    const info = await this.deviceInfo()
    return info.platform === 'android' || info.platform === 'ios'
  },

  /**
   * Returns true if we are running on mobile (small screen).
   */
  isMobileWidth(): boolean {
    return window.innerWidth <= MOBILE_WIDTH
  },

  /**
   * Returns true if we are running on mobile with big screen (e.g. phablets and foldable phones).
   */
  isMediumWidth(): boolean {
    return window.innerWidth <= MEDIUM_WIDTH
  },

  /**
   * Returns true if we are running on tablet.
   */
  isTabletWidth(): boolean {
    return window.innerWidth > MOBILE_WIDTH && window.innerWidth <= TABLET_WIDTH
  },

  /**
   * Returns true if we are running on anything larger than a tablet (e.g. a desktop).
   */
  isDesktopWidth(): boolean {
    return window.innerWidth > TABLET_WIDTH
  },

  /**
   * Returns true if the device has a touch screen.
   */
  isTouchScreen(): boolean {
    // `hover: none` matches when the primary input
    // of the device cannot hover or cannot conveniently hover
    // (on smartphones, usually there is a hover state, but it
    // requires a long press, so it still returns `none`).
    //
    // `pointer: coarse` matches when when the primary input
    // of the device has limited accucary, such as touch screens.
    //
    // More details: https://www.w3.org/TR/mediaqueries-4/#mf-interaction
    return (
      window.matchMedia('(hover: none)').matches ||
      window.matchMedia('(pointer: coarse)').matches
    )
  },

  async openUrl(url: string): Promise<void> {
    // @ts-ignore
    await this._data.openUrl(url)
  },
}
